import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function IntencjeLowiczki() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Intecje Łowiczki</div>
                <ul>
                    <li>Niedziela 16.02 ++ Jan Kozaczka, Józef Weronika Anteccy</li>
                    <li>Niedziela 23.02 ++ Andrzej Głąb, rodzice, teściowie, Wiesław brat, Wiesława bratowa, Urszula c., Marek zięć</li>
                    <li>Niedziela 2.03 + Jacek Czopek</li>
                    <li>Środa 5.03 ++ Julian Maria Stachura, Jan s.</li>
                    <li>Piątek 7.03 + Leszek Lewański (od bratanka Pawła z rodz.)</li>
                    <li>Niedziela 9.03 ++ Jan Zofia Głąb, Andrzej Wiesław s.</li>
                    <li>Piątek 14.03 ++ Władysław Rozalia Gancarczyk, Józefa c.</li>
                    <li>Niedziela 16.03 + Stanisław Zięba 2 rocz.</li>
                    <li>Piątek 21.03 ++ Ludwik Rozalia 10 rocz. Kozak, rodzice</li>
                    <li>Niedziela 23.03 ++ Mieczysław Józefa 1 rocz. Żydek</li>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default IntencjeLowiczki