import '../App.css'
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'
import './repeat.css'

function Ofiarodawcy(){
    return (
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>OFIARA  NA POTRZEBY  KOŚCIOŁA STYCZEŃ 2025</div>
                <table>
                    <tr><td>1</td><td>Brzeźniak – Łowiczki Graniczna 38</td><td>100zł</td></tr>
                    <tr><td>2</td><td>Czopek – Łowiczki Centralna 216</td><td>100zł</td></tr>
                    <tr><td>3</td><td>A.B.Głąb – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>4</td><td>J.J.Głąb – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>5</td><td>S.A.Głąb – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>6</td><td>A.A. Jezierscy – Łowiczki Centralna 260</td><td>100zł</td></tr>
                    <tr><td>7</td><td>Anna Kciuk – Łowiczki Centralna 6</td><td>50zł</td></tr>
                    <tr><td>8</td><td>A.Kozaczka – Łowiczki Kanada 22</td><td>50zł</td></tr>
                    <tr><td>9</td><td>Jan Urszula Kreczmer – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>10</td><td>J.A.Majkut – Łowiczki Centralna 85</td><td>100zł</td></tr>
                    <tr><td>11</td><td>Leszek Dorota Majkut – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>12</td><td>W.A.Matla – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>13</td><td>Józef Miętka – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>14</td><td>A.P.Oleś – Łowiczki Centralna 105</td><td>100zł</td></tr>
                    <tr><td>15</td><td>Anna Pawłowska – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>16</td><td>Artur Jolanta Pawłowscy – Łowiczki</td><td>200zł</td></tr>
                    <tr><td>17</td><td>M.J.Seremet – Łowiczki</td><td>200zł</td></tr>
                    <tr><td>18</td><td>H.K.Stachura – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>19</td><td>B.A.Wojtaszek – Łowiczki</td><td>100zł</td></tr>
                    <tr><td>20</td><td>C.P.Woźniak – Łowiczki Centralna 85</td><td>100zł</td></tr>
                    <tr><td>21</td><td>Bronisława Antecka – Andruchowska 113</td><td>100zł</td></tr>
                    <tr><td>22</td><td>M.L.Anteccy – Andrychowska 13</td><td>100zł</td></tr>
                    <tr><td>23</td><td>K.W.B. - Andrychowska 256</td><td>100zł</td></tr>
                    <tr><td>24</td><td>R.Bagier – Wrzosowa 17</td><td>100zł</td></tr>
                    <tr><td>25</td><td>Bernaś, Momot – Akacjowa 31</td><td>100zł</td></tr>
                    <tr><td>26</td><td>Stanisław Maria Boba – Andrychowska 352</td><td>100zł</td></tr>
                    <tr><td>27</td><td>Rafał Bratek – Akacjowa 89</td><td>100zł</td></tr>
                    <tr><td>28</td><td>Andrzej Byrski – Bukowiecka 50</td><td>200zł</td></tr>
                    <tr><td>29</td><td>M.G.Całus</td><td>200zł</td></tr>
                    <tr><td>30</td><td>B.B.Cuber</td><td>100zł</td></tr>
                    <tr><td>31</td><td>Maria Cygan – Pagórkowa</td><td>100zł</td></tr>
                    <tr><td>32</td><td>K.Domider – Andrychowska 216</td><td>100zł</td></tr>
                    <tr><td>33</td><td>Dziedzic – Lipowa</td><td>100zł</td></tr>
                    <tr><td>34</td><td>Frączek – Andrychowska 265</td><td>100zł</td></tr>
                    <tr><td>35</td><td>Anna Frączek – Wrzosowa 22</td><td>100zł</td></tr>
                    <tr><td>36</td><td>I.M.Frączek – Andrychowska 159</td><td>100zł</td></tr>
                    <tr><td>37</td><td>J.R.Frączek – Akacjowa 6</td><td>50zł</td></tr>
                    <tr><td>38</td><td>I.S. Frączek – Andrychowska 163</td><td>300zł</td></tr>
                    <tr><td>39</td><td>T.J.Frączek</td><td>100zł</td></tr>
                    <tr><td>40</td><td>T.K.Frączek – Andrychowska 297</td><td>100zł</td></tr>
                    <tr><td>41</td><td>Marcin Gagracz – Akacjowa 96</td><td>100zł</td></tr>
                    <tr><td>42</td><td>W.Z.Gąsiorek – Andrychowska 374</td><td>100zł</td></tr>
                    <tr><td>43</td><td>Czesława Gluzicka – Akacjowa 86</td><td>50zł</td></tr>
                    <tr><td>44</td><td>Józef Głąb – Modrzewiowa</td><td>200zł</td></tr>
                    <tr><td>45</td><td>J.N.Głąb – Modrzewiowa</td><td>100zł</td></tr>
                    <tr><td>46</td><td>W.M.Gorzkowscy</td><td>100zł</td></tr>
                    <tr><td>47</td><td>Elżbieta Gregorczyk – Wrzosowa 16</td><td>100zł</td></tr>
                    <tr><td>48</td><td>Górecki, Kobiałka</td><td>200zł</td></tr>
                    <tr><td>49</td><td>A.T.Góreccy – Lipowa 14</td><td>100zł</td></tr>
                    <tr><td>50</td><td>E.F.Góreccy – Akacjowa 44</td><td>100zł</td></tr>
                    <tr><td>51</td><td>Krzysztof Górecki – Kasztanowa 125</td><td>300zł</td></tr>
                    <tr><td>52</td><td>Hałatek – Lipowa</td><td>100zł</td></tr>
                    <tr><td>53</td><td>Piotr Jurecki – Andrychowska 2</td><td>100zł</td></tr>
                    <tr><td>54</td><td>Kalman, Ściera – Kasztanowa 45</td><td>100zł</td></tr>
                    <tr><td>55</td><td>Kędzierscy – Andrychowska 194</td><td>100zł</td></tr>
                    <tr><td>56</td><td>S.A.Kolasa – Andrychowska 173</td><td>100zł</td></tr>
                    <tr><td>57</td><td>Rozalia Janusz Kozłowscy – Wrzosowa 24</td><td>200zł</td></tr>
                    <tr><td>58</td><td>Edward Knutel – Bukowiecka 15a</td><td>500zł</td></tr>
                    <tr><td>59</td><td>Marek Knutel – Bukowiecka 15a</td><td>600zł</td></tr>
                    <tr><td>60</td><td>Z.Knutel – Modrzewiowa 3</td><td>50zł</td></tr>
                    <tr><td>61</td><td>Kowalczyk – Modrzewiowa 14</td><td>200zł</td></tr>
                    <tr><td>62</td><td>A.K.Krawczyk – Akacjowa</td><td>100zł</td></tr>
                    <tr><td>63</td><td>D.Krzyścin – Pagórkowa</td><td>100zł</td></tr>
                    <tr><td>64</td><td>M.Krzyścin, A.M.Jureccy – Andrychowska 196</td><td>100zł</td></tr>
                    <tr><td>65</td><td>Kubas – Andrychowska 380</td><td>50zł</td></tr>
                    <tr><td>66</td><td>Kubiczek – Bukowiecka 16</td><td>200zł</td></tr>
                    <tr><td>67</td><td>T.J.Kusak – Andrychowska 238</td><td>50zł</td></tr>
                    <tr><td>68</td><td>Jerzy Kuska – Lipowa 73</td><td>100zł</td></tr>
                    <tr><td>69</td><td>Stanisław Kuska – Kasztanowa 4</td><td>50zł</td></tr>
                    <tr><td>70</td><td>B.A.Kuwik – Lipowa 57</td><td>200zł</td></tr>
                    <tr><td>71</td><td>Kuśpik – Andrychowska 174</td><td>100zł</td></tr>
                    <tr><td>72</td><td>Łubiarz – Lipowa 65</td><td>100zł</td></tr>
                    <tr><td>73</td><td>Rafał Katarzyna Madeja – Andrychowska 196</td><td>200zł</td></tr>
                    <tr><td>74</td><td>Makuch – Andrychowska 134</td><td>100zł</td></tr>
                    <tr><td>75</td><td>Makuch – Andrychowska 288</td><td>100zł</td></tr>
                    <tr><td>76</td><td>Józef Makuch – Akacjowa</td><td>100zł</td></tr>
                    <tr><td>77</td><td>Józef Makuch – Andrychowska 339</td><td>100zł</td></tr>
                    <tr><td>78</td><td>Sebastian Makuch</td><td>300zł</td></tr>
                    <tr><td>79</td><td>Waldemar Makuch – Akacjowa</td><td>100zł</td></tr>
                    <tr><td>80</td><td>Michalska</td><td>100zł</td></tr>
                    <tr><td>81</td><td>Jan Halina Mleczek – Głębowicka 3</td><td>500zł</td></tr>
                    <tr><td>82</td><td>Stanisław Młynarczyk – Akacjowa 56</td><td>100zł</td></tr>
                    <tr><td>83</td><td>Wiktoria Noworyta</td><td>50zł</td></tr>
                    <tr><td>84</td><td>E.Nykiel – Lipowa 72</td><td>100zł</td></tr>
                    <tr><td>85</td><td>Krzysztof Oczkoś – Akacjowa 11</td><td>100zł</td></tr>
                    <tr><td>86</td><td>D.K.Oleksy – Pagórkowa 3</td><td>100zł</td></tr>
                    <tr><td>87</td><td>Osowcy, Komendera – Andrychowska 402</td><td>200zł</td></tr>
                    <tr><td>88</td><td>Płonka – Andrychowska 299</td><td>100zł</td></tr>
                    <tr><td>89</td><td>Krystian Urszula Poręba</td><td>50zł</td></tr>
                    <tr><td>90</td><td>S.W.Poręba</td><td>100zł</td></tr>
                    <tr><td>91</td><td>Mirosław Renata Rogalscy – Andrychowska 229</td><td>2000zł</td></tr>
                    <tr><td>92</td><td>Rozalia Rola – Andrychowska 225</td><td>200zł</td></tr>
                    <tr><td>93</td><td>E.M.Skawina – Modrzewiowa 10</td><td>100zł</td></tr>
                    <tr><td>94</td><td>Dorota Stokłosa</td><td>100zł</td></tr>
                    <tr><td>95</td><td>Szatan – Andrychowska 272</td><td>100zł</td></tr>
                    <tr><td>96</td><td>B.A.Szymańscy – Andrychowska 410</td><td>100zł</td></tr>
                    <tr><td>97</td><td>Śliwińska – Kasztanowa 112</td><td>50zł</td></tr>
                    <tr><td>98</td><td>A.A.Środa – Akacjowa 77</td><td>100zł</td></tr>
                    <tr><td>99</td><td>M.R.Tobiczyk – Andrychowska 97</td><td>100zł</td></tr>
                    <tr><td>100</td><td>Gabriela Toporek</td><td>100zł</td></tr>
                    <tr><td>101</td><td>Jarosław Wanat – Lipowa 41</td><td>100zł</td></tr>
                    <tr><td>102</td><td>Bożena Wędrzyk – Kasztanowa 14</td><td>300zł</td></tr>
                    <tr><td>103</td><td>Łukasz Wiśniecki – Lipowa 43</td><td>100zł</td></tr>
                    <tr><td>104</td><td>A.Włoszek</td><td>50zł</td></tr>
                    <tr><td>105</td><td>Wnętrzak</td><td>200zł</td></tr>
                    <tr><td>106</td><td>Wojtala – Andrychowska 172</td><td>50zł</td></tr>
                    <tr><td>107</td><td>Wójcik – Andrychowska 156</td><td>600zł</td></tr>
                    <tr><td>108</td><td>Jolanta Ulczok – Kasztanowa 30</td><td>50zł</td></tr>
                    <tr><td>109</td><td>K.Z. - Pagórkowa</td><td>200zł</td></tr>
                    <tr><td>110</td><td>Bogusław Zajas – Akacjowa 10</td><td>100zł</td></tr>
                    <tr><td>111</td><td>A.C.Zając – Andrychowska 111</td><td>100zł</td></tr>
                    <tr><td>112</td><td>J.K.Zając – Andrychowska 101</td><td>100zł</td></tr>
                    <tr><td>113</td><td>I.Ł.Zaporowscy – Andrychowska 366</td><td>100zł</td></tr>
                    <tr><td>114</td><td>S.M.Zawierucha – Lipowa 23</td><td>100zł</td></tr>
                    <tr><td>115</td><td>Zdżalik – Wrzosowa 25</td><td>50zł</td></tr>
                    <tr><td>116</td><td>Ziaja – Andrychowska</td><td>50zł</td></tr>
                    <tr><td>117</td><td>I.T.Zielińscy – Andrychowska 29</td><td>100zł</td></tr>
                    <tr><td>118</td><td>A.Ł.Zięba – Andrychowska 351</td><td>200zł</td></tr>
                    <tr><td>119</td><td>Stanisława Zięba – Andrychowska 400</td><td>200zł</td></tr>
                    <tr><td>120</td><td>Józef Zimnal – Kasztanowa 111</td><td>100zł</td></tr>
                    <tr><td>121</td><td>J.C.Zygmunt – Lipowa 15</td><td>100zł</td></tr>
                    <tr><td>122</td><td>Zbigniew Ewa Żmuda – Akacjowa 26</td><td>100zł</td></tr>
                    <tr><td>123</td><td>Akacjowa 24</td><td>200zł</td></tr>
                    <tr><td>124</td><td>Andrychowska 38</td><td>100zł</td></tr>
                    <tr><td>125</td><td>Andrychowska 260</td><td>50zł</td></tr>
                    <tr><td>126</td><td>Andrychowska 301</td><td>100zł</td></tr>
                    <tr><td>127</td><td>Andrychowska 302a</td><td>100zł</td></tr>
                    <tr><td>128</td><td>Andrychowska 338</td><td>50zł</td></tr>
                    <tr><td>129</td><td>Jaśminowa 16</td><td>100zł</td></tr>
                    <tr><td>130</td><td>Kasztanowa 49</td><td>100zł</td></tr>
                    <tr><td>131</td><td>Kasztanowa 81</td><td>100zł</td></tr>
                    <tr><td>132</td><td>Kościelna 16</td><td>50zł</td></tr>
                    <tr><td>133</td><td>Lipowa 61</td><td>100zł</td></tr>
                    <tr><td>134</td><td>Wrzosowa 27</td><td>50zł</td></tr>
                    <tr><td>135</td><td>Anonimowo</td><td>50zł</td></tr>
                    <tr><td>136</td><td>Anonimowo</td><td>100zł</td></tr>
                    <tr><td>137</td><td>Anonimowo</td><td>1000zł</td></tr>
                    <tr><td>138</td><td>Anonimowo</td><td>1000zł</td></tr>
                    <tr><td>139</td><td>Anonimowo</td><td>2000zł</td></tr>
                </table>
            </div>

            <Footer />
        </>
)}

export default Ofiarodawcy