import '../App.css';
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer';
import { globalVar } from '../globalVar';
function Ogloszenia() {
  return (<>
        <Navigation />
        <div className='article'>
            <div className='title'>Ogłoszenia {globalVar.map((map,index) => {return map.niedziela})} </div>
            <p>Kalendarz Liturgiczny</p>
            <ul>
                <li>poniedziałek- wspomnienie św.Scholastyki, dziewicy</li>
                <li>piątek - święto św.św.Cyryla i Metodego, biskupów, patronów Europy</li>
            </ul>
            <p>
                We wtorek przypada Światowy Dzień Chorego, jak każdego roku modlić się
                będziemy w intencji naszych chorych o ulgę w cierpieniu, cierpliwość w znoszeniu
                choroby, wieku i samotności. Proszę o pomoc w dotarciu w tym dniu do kościoła chorym i
                starszym. O godz. 10.00 będzie odprawiona Msza św. z obrzędem przyjęcia sakramentu
                Namaszczenia Chorych, zapraszam na tę Msze również rodziny naszych chorych i
                starszych, jak i tych wszystkich, którzy pełnią posługe miłosierdzia wobec nich, a od godz.
                9.30 możliwość skorzystania z sakramentu pokuty.
            </p>
            <p>
                W środę o godz. 18.00 Msza św. i Nowenna do MBNP. Przed Mszą św. różaniec
                prowadzony przez I Różę w intencji chorych i starszych naszej parafii oraz tych, którzy się
                nimi opiekują.
            </p>
            <p>
                Dziękuję za sprzątanie kościoła na dzisiejszą niedzielę i ofiarę 100zł, na przyszłą
                niedzielę troskę o świątynię powierzamy rodzinom:
            </p>
            <ul>
                <li>Józefa i Ewy Kozak</li>
                <li>Antoniego i Doroty Orlickich</li>
                <li>Michała i Kingi Orlickich</li>
                <li>Stanisława i Anny Kuska</li>
                <li>Leszka Kuski</li>
            </ul>
            <p>
                Dziękuję za sprzątanie kaplicy na dzisiejszą niedzielę i ofiarę 200zł, na przyszłą
                niedzielę troskę o kaplicę powierzamy rodzinom:
            </p>
            <ul>
                <li>Artura Seremeta</li>
                <li>Marcina Balcera</li>
                <li>Roberta Stachury</li>
                <li>Tadeusza i Anny Gancarczyk</li>
            </ul>
            <p>
                Radni Piotrowic zapraszają mieszkańców do remizy OSP w dniu 16 lutego o
                godz.12.00 na spotkanie informacyjne w sprawie Ośrodka Zdrowia w Piotrowicach.
            </p>
        </div>
        <Footer />
  </>
  );
}

export default Ogloszenia;
